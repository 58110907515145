
.my-custom-class {
  input[type=text], input[type=password] {
    border-radius: 0;
    padding: 10px;
    background-color: #ffffff;
    border: 0.5px solid #c9c9c9;
    color: black;
  }
}
